@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind primereact;

body{
    background-image: url("../images/bg.jpg");
    background-size: cover;
    background-repeat:  no-repeat;
    background-attachment: fixed;
}

#backdrop-blur{
    backdrop-filter: blur(10px);
}

#backdrop-white{
    background-color:  rgba(255, 255, 255, 0.287);
}

input:not(#addTabsInRoles,#todoAppInput,#userControlsInput,#annoncunmentInput,#chatInput,#searchGameModal) {
    outline: 0;
    transition: all 0.3s ease;
    border: 1px solid rgba(140, 111, 149, 0.5); 
    border-radius: 4px; 
    padding: 10px; 
    box-shadow: 0 0 5px rgba(140, 111, 149, 0.5);
}

input:focus:not(#addTabsInRoles,#todoAppInput,#userControlsInput,#annoncunmentInput,#chatInput,#searchGameModal)  {
    outline: none;
    border: 2px solid rgba(140, 111, 149, 0.706); 
    transform: scale(1.02); 
    box-shadow: 0 0 10px rgba(140, 111, 149, 0.706); 
}


.inter-700 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.inter-600 {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.inter-500 {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.inter-400 {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.text-fuchsia-700{
    color: #e71bcf !important;
}

.darkMode{
    background-color: rgb(40, 39, 39) !important;
}

.calc-max-w{
    width: calc(100vw - 350px) !important;
}
